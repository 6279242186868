<script>
    /* eslint-disable*/
    import numeral from 'numeral';

    export default {
        name: 'number-formatter-input',
        data: function () {
            return {
                isFocus: false,
            };
        },
        props: {
            isTotalManual: {
                default: false,
            },
            value: 0,
            percentage: {
                default: false
            },
            format: {
                type: String,
                default: '$0,0'
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            isReadonly() {
                return this.isDisabled || this.readonly;
            }
        },
        mounted: function () {
            this.formatValue();
        },
        watch: {
            value: function () {
                if (!this.isFocus) {
                    let vm = this;
                    setTimeout(function () {
                        vm.formatValue();
                    }, 100);
                }
            }
        },
        methods: {
            onChange: function (e) {
                this.$emit('onChange', e, this.value);
            },
            updateValue: function (value) {
                if (this.isTotalManual && (value == '' || value == null)) {
                    var result = null;
                } else {
                    var result = value == '0.0' ? '0.0' : value == '.' ? '0.' : numeral(value).format('0.[00]');
                }
                this.$emit('input', result);
            },
            formatValue: function () {
                if (this.isTotalManual && this.value === null) {
                    //console.log('update.null');
                    return;
                }
                if (this.percentage) {
                    if ((numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) > 100) {
                        this.$refs.input.value = 100 + '%';
                    } else {
                        this.$refs.input.value = (numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) + '%';
                    }
                } else if (this.value !== '' && !this.isTotalManual) {
                    this.$refs.input.value = numeral(this.value).format(this.format);
                    this.$emit('blur');
                } else if (this.value !== null && this.isTotalManual) {
                    this.$refs.input.value = numeral(this.value).format(this.format);
                    this.$emit('blur');
                }
            },
            selectAll: function (event) {
                this.isFocus = true;
                setTimeout(function () {
                    event.target.select();
                }, 0);
            },

            setValue: function (val) {
                let result = numeral(val).format('0.[00]');
                let formattedResult = numeral(result).format(this.format);
                this.$emit('input', result);
                let vm = this;
                setTimeout(function () {
                    vm.$refs.input.value = formattedResult;
                }, 0);
            },
            emitEvent: function (event) {
                if (event == 'onKeyEnter') {
                    this.formatValue();
                }
                this.$emit(event);
            },
            focus: function () {
                this.isFocus = true;
                this.$refs.input.focus();
            },
            select: function () {
                this.isFocus = true;
                this.$refs.input.select();
            },
            click: function () {
                this.isFocus = true;
                this.$refs.input.click();
            },
            blur: function () {
                this.isFocus = false;
                this.formatValue();
            }
        }
    };

</script>

<template>
    <input ref="input"
           v-bind:value="value"
           v-on:keypress="emitEvent('onKeyPress')"
           v-on:keydown.left="emitEvent('onKeyLeft')"
           v-on:keydown.right="emitEvent('onKeyRight')"
           v-on:keydown.up="emitEvent('onKeyUp')"
           v-on:keydown.down="emitEvent('onKeyDown')"
           v-on:keydown.enter="emitEvent('onKeyEnter')"
           v-on:input="updateValue($event.target.value)"
           v-on:focus="selectAll"
           v-on:blur="blur"
           :readonly="isReadonly"
           @change="onChange($event)">
</template>





